<script setup>
import { load } from "cheerio";
import axios from "axios";
import CommentsList from "~/components/comments-list.vue";
import MD5 from "crypto-js/md5";
import dayjs from "dayjs";
import {
	AdButlerItem,
	AdButlerBanner
} from "@stockstotrade_com/stt-ts-shared-lib";

const config = useRuntimeConfig();
const { showAds, showAdButlerBanner, hideBanner } = useAdbutlerChecker();

const {
	WEET_HOST,
	WEET_KEY,
	WEET_MAK_HOST,
	WEET_MAK_TLD,
	WEET_MAK_KEY,
	WEET_ITR_LIST_ID,
	show_adbutler,
	env
} = config.public;

const adPlacement = ref();
const entryContent = ref(null);
const isLoading = ref(true);
const $router = useRoute();
const comments = ref([]);
const post_category = ref(null);
const stock_referenced = ref([]);
const { $postsListDict, $categories } = useNuxtApp();
const fetchCategoryInformation = async ($hostname, $slug) => {
	return $fetch($hostname + "graphql", {
		method: "post",
		body: {
			query: `
              query NewQuery {
				postBy(uri: "${$slug}"){
					categories {
						nodes {
							name
							slug
							posts(where: {orderby: {order: DESC, field: DATE}}) {
								nodes {
									title
									slug
									dateGmt
									content
									categories {
										nodes {
											name
											slug
										}
									}
									featuredImage {
										node {
											sourceUrl
										}
									}
								}
							}
						}
					}
				}
			}`
		}
	});
};

const fetchPostGraphQl = async ($hostname, $route) => {
	try {
		const response = await $fetch($hostname + "graphql", {
			method: "post",
			body: {
				query: `
            query NewQuery {
              postBy(slug: "${$route}") {
				postId
              }
            }`
			}
		});
		return response;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
	}
};
const fetchLeftOverInformation = async () => {
	const host = config.public["hostname"];
	const current_hostname = config.public["current_hostname"];

	const commentsResponse = await $fetch(
		`${current_hostname}/.netlify/functions/get-comments/${data.value.post.id}`
	);

	if (commentsResponse) {
		comments.value = commentsResponse;
	}

	if (data.value && data.value.type === "posts") {
		const { data } = await fetchCategoryInformation(host, $router.path);

		if (data && data.postBy) {
			const {
				categories: { nodes }
			} = data.postBy;

			post_category.value = nodes[0];
		}
	}
};

const { data } = await useAsyncData(
	"post_data",
	async ({ $config, $router }) => {
		let payload = {
			post: null,
			metadata: null,
			faq: null
		};

		let element =
			$postsListDict[decodeURIComponent($router.currentRoute._rawValue.path)];

		if (!element) {
			const response = await fetchPostGraphQl(
				$config.public["hostname"],
				$router.currentRoute._rawValue.path
			);

			if (!response.data.postBy) {
				throw createError({
					statusCode: 404,
					statusMessage: "Page Not Found",
					fatal: true
				});
			}
		}

		const response = await $fetch(
			`${$config.public["hostname"]}wp-json/wp/v2/${element.type}/${element.id}?_embed`
		);

		let faqResponse = null;
		if ($config.public.env === "PROD" || $config.public.env === "test") {
			faqResponse = await $fetch(
				`${$config.public["current_hostname"]}.netlify/functions/faq-code?page=${$router.currentRoute._rawValue.path}`
			);

			if (faqResponse && faqResponse.FAQPage) {
				const questions = faqResponse.FAQPage.mainEntity.map((entry) => {
					return { question: entry.name, answer: entry.acceptedAnswer.text };
				});

				payload.faq = questions;
			}
		}

		const schema = schemaMaker(
			response,
			$config.public["current_hostname"],
			$categories,
			$config.public["hostname"],
			faqResponse && faqResponse.body !== "no-faq" ? faqResponse : null
		);

		const { yoast_head_json: metadata, ...post } = response;

		payload = {
			...payload,
			post,
			metadata,
			schema: {
				"@context": "https://schema.org",
				"@graph": schema
			},
			type: element.type
		};

		return { ...payload };
	}
).catch((error) => {
	if (env === "dev") console.log("SLUG PAGE ERROR", { error });
	throw createError({
		statusCode: error.cause.statusCode,
		fatal: true
	});
});

if (!data.value) {
	throw createError({
		statusCode: 404,
		statusMessage: "Page Not Found",
		fatal: true
	});
}

const { post, metadata, schema, faq: currentFaqs } = data.value;

const head_seo = computed(() => {
	if (metadata) {
		const experiment = Object.entries(metadata);
		const filtered = experiment.filter(
			(item) =>
				![
					"title",
					"robots",
					"canonical",
					"og_url",
					"schema_json",
					"WordPressAuthor_YoastHeadJson_Schema_"
				].includes(item[0])
		);
		return filtered;
	}

	return null;
});

const twitterScriptParser = () => {
	const existingScript = document.querySelector(
		'script[src="https://platform.twitter.com/widgets.js"]'
	);

	if (!existingScript) {
		let twitterScript = document.createElement("script");
		twitterScript.setAttribute("src", "https://platform.twitter.com/widgets.js");
		const body = document.querySelector("body");
		body.appendChild(twitterScript);
	}
};

const shouldLoadConvertFlow = () => {
	const divContainer = document.getElementsByClassName("cf-cta-snippet")[0];

	if (divContainer) {
		return true;
	} else {
		return false;
	}
};

const loadJQuery = () => {
	let scriptElement = document.createElement("script");
	scriptElement.src =
		"https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
	scriptElement.onLoad = convertFlowScriptParser();
	document.head.appendChild(scriptElement);
};

const convertFlowScriptParser = () => {
	let scriptElement = document.createElement("script");
	scriptElement.async = true;
	scriptElement.src = "https://js.convertflow.co/production/websites/8742.js";

	const body = document.querySelector("body");
	body.appendChild(scriptElement);
};

const removeConvertFlowScript = () => {
	const existingScript = document.querySelector(
		'script[src="https://js.convertflow.co/production/websites/8742.js"]'
	);

	if (existingScript) {
		window.convertflow = undefined;
		existingScript.remove();
	}
};

// Function to modify the src or href attribute
const fixPath = (element, attribute, contentToParse = "image") => {
	const path = element.attr(attribute);

	if (contentToParse === "link") {
		const current_path = config.public.hostname.split("://");

		if (
			!path.includes("cfn.stockstotrade.com") &&
			!path.includes("lp.stockstotrade.com") &&
			path.includes(current_path[1]) &&
			path.includes("finance") &&
			!path.startsWith(config.public.current_hostname) &&
			!path.includes("/wp-content/")
		) {
			element.attr(
				attribute,
				config.public.current_hostname.split("://").join("://finance.") +
					path.split(current_path[1])[1]
			);
		} else if (
			!path.includes("cfn.stockstotrade.com") &&
			!path.includes("lp.stockstotrade.com") &&
			path.includes(current_path[1]) &&
			!path.startsWith(config.public.current_hostname) &&
			!path.includes("/wp-content/")
		) {
			element.attr(
				attribute,
				config.public.current_hostname + path.split(current_path[1])[1]
			);
		}
	}

	if (element[0].name == "img") {
		if (
			path.startsWith("/wp-content/") &&
			!path.startsWith(config.public.hostname.slice(0, -1))
		) {
			element.attr(attribute, config.public.hostname.slice(0, -1) + path);
		}

		if (element[0].attribs.src.includes("http://")) {
			element[0].attribs.src = element[0].attribs.src.replace(
				"http://",
				"https://"
			);
		}
		if (element[0].attribs.src.includes("http://")) {
			element[0].attribs.src = element[0].attribs.src.replace(
				"http://",
				"https://"
			);
		}
		if (
			element[0].attribs.src.includes("https://contentstt.wpenginepowered.com/")
		) {
			element[0].attribs.src = element[0].attribs.src.replace(
				"https://contentstt.wpenginepowered.com/",
				config.public.hostname
			);
		}
	}
};

const decodeHTMLEntities = (str) => {
	const entities = {
		"&#8220;": "“",
		"&#8221;": "”",
		"&#8243;": "″",
		"&#8217;": "’"
	};

	return str.replace(/&#[0-9]+;/g, (match) => entities[match] || match);
};

const extractShortcode = (content) => {
	const regex = /\[screeners-widget (.*?)\]/g;
	let match;

	while ((match = regex.exec(content)) !== null) {
		let shortcodeContent = match[1];
		shortcodeContent = decodeHTMLEntities(shortcodeContent);
		shortcodeContent = shortcodeContent
			.replace(/″/g, '"')
			.replace(/'/g, '"')
			.replace(/”/g, '"')
			.replace(/“/g, '"')
			.replace(/’/g, '"')
			.replace(/″/g, ":")
			.replace(/=/g, ":")
			.split(" ");

		const params_string = shortcodeContent
			.find((param) => param.startsWith("params:"))
			.split(`params:"`)[1]
			.slice(0, -1);

		const params_value = JSON.parse(params_string);
		console.log({ params_value });

		const arr = Object.keys(params_value).map((key) => {
			return [key, params_value[key]];
		});

		return {
			widgetName: shortcodeContent[0].split(':"')[1].slice(0, -1),
			params: arr
		};
	}

	return null;
};

const widgetContentFormatter = (content) => {
	const newParser = extractShortcode(content);

	if (!newParser) return content;

	let params;
	let name;
	let sections;

	name = newParser.widgetName;
	params = newParser.params.map(([key, value]) => `${key}=${value}`).join("&");

	sections = content.split(/\[screeners-widget (.*?)\]/g);

	const iframe = `<iframe id="${name}"
                     width="786"
                     height="610"
                     title="Screener"
                     src="https://scn-wdgt-01-dev.timothysykes.com/?${params}"></iframe>`;

	return sections[0] + iframe + sections[2];
};

const contentFormatter = (content) => {
	const $ = load(widgetContentFormatter(content), {
		decodeEntities: false, // Prevent HTML entity decoding
		xmlMode: false // Do not treat input as XML
	});
	$("script").each((index, scriptElement) => {
		const scriptSrc = $(scriptElement).attr("src");

		if (
			scriptSrc &&
			scriptSrc.includes(
				"https://wdgt01.contentstt.wpenginepowered.com:8082/js/compiled/embede/sttwidget.min.js"
			)
		) {
			$(scriptElement).attr(
				"src",
				"https://wdgt01.stockstotrade.com:8082/js/compiled/embede/sttwidget.min.js"
			);
		}

		if ($(scriptElement).attr("async")) {
			// Remove the async attribute
			$(scriptElement).removeAttr("async");
			// Add the defer attribute
			$(scriptElement).attr("defer", "");
		}
	});

	//Remove convertflow script
	$(
		'script:contains("https://js.convertflow.co/production/websites/8742.js")'
	).remove();

	$("iframe").each((index, iframeEl) => {
		if (!$(iframeEl).attr("loading")) {
			$(iframeEl).attr("loading", "lazy");
		}
	});

	$("textarea").each((index, textareaElement) => {
		if ($(textareaElement).attr("cols")) {
			$(textareaElement).removeAttr("cols");
		}
	});

	$("img").each((index, imgElement) => {
		if (!imgElement.attribs.src) return;
		fixPath($(imgElement), "src", "image");

		if (imgElement.attribs["srcset"]) {
			const og_srcset = imgElement.attribs["srcset"].split(",");

			const srcset = og_srcset
				.map((src) => {
					let split = src.split(" ");

					if (split[0] === "") split.shift();

					return `${
						config.public.hostname
					}cdn-cgi/image/quality=100,format=webp,width=${split[1].split("w")[0]}/${
						split[0]
					} ${split[1]}`;
				})
				.join(", ");

			$(imgElement).attr("srcset", srcset);
		}
	});

	let regex = /\((NASDAQ|NYSE):\s*([A-Z]+)\)/;

	$("p").each((index, pElement) => {
		let text = $(pElement).text();

		// Regular expressions to capture stock symbols in the format "(NASDAQ: [Symbol])" or "(NYSE: [Symbol])"

		let matches = text.match(regex);

		if (matches && matches[2]) {
			// matches[2] contains the captured stock symbol
			const stockSymbol = matches[2]; // The stock symbol following 'NASDAQ:' or 'NYSE:'

			if (!stock_referenced.value.includes(stockSymbol)) {
				stock_referenced.value.push(stockSymbol);
			}
		}

		if (index === 0) {
			const $pElement = $(pElement);
			const $firstImg = $pElement.find("img").first();

			if ($firstImg.length > 0) {
				$firstImg.remove();
			}
		}
	});

	$("h3").each((index, h3Element) => {
		let text = $(h3Element).text();
		let matches = text.match(regex);

		if (matches && matches[2]) {
			// matches[2] contains the captured stock symbol
			const stockSymbol = matches[2]; // The stock symbol following 'NASDAQ:' or 'NYSE:'

			if (!stock_referenced.value.includes(stockSymbol)) {
				stock_referenced.value.push(stockSymbol);
			}
		}
	});
	$("td").each((_, tdElement) => {
		let text = $(tdElement).text();
		// Extended regular expressions to match 'NYSE:' and 'NASDAQ:', capturing the next word even if followed by punctuation

		let regexNYSE = /(NYSE:)(?!.*NYSE:)/;
		let regexNASDAQ = /(NASDAQ:)(?!.*NASDAQ:)/;

		if (regexNYSE.test(text) || regexNASDAQ.test(text)) {
			const content = $(tdElement).text().split(": ");

			$(tdElement).html(
				`<span class="stock-link">${content[0]}: <span data-stock>${content[1]}</span></span>`
			);
		}
	});

	$("a").each((index, aElement) => {
		if (!aElement.attribs.href) return;

		const imageExtensions = [".webp", ".jpg", ".jpeg", ".png", ".gif"];

		fixPath($(aElement), "href", "link");
		fixPath($(aElement), "href");

		const isImageLink = stringEndsWithOneOfWords(
			aElement.attribs.href,
			imageExtensions
		);

		let text = $(aElement).text();

		// Regular expressions to match 'NYSE:' and 'NASDAQ:' not preceded by '('

		let regexNYSE = /(NYSE:)(?!.*NYSE:)/;
		let regexNASDAQ = /(NASDAQ:)(?!.*NASDAQ:)/;

		if (regexNYSE.test(text) || regexNASDAQ.test(text)) {
			const content = $(aElement).text().split(": ");

			if (!stock_referenced.value.includes(content[1])) {
				stock_referenced.value.push(content[1]);
			}

			$(aElement).html(`${content[0]}: <span  data-stock>${content[1]}</span>`);
		}
		if (
			aElement.attribs.href.includes("http://") &&
			aElement.attribs.href.includes(config.public.hostname.split("https://")[1])
		) {
			aElement.attribs.href = aElement.attribs.href.replace("http://", "https://");
		}

		if (
			aElement.attribs.href.includes("https://lp.contentstt.wpenginepowered.com/")
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"https://lp.contentstt.wpenginepowered.com/",
				"https://lp." + config.public.current_hostname.split("//")[1]
			);
		}

		if (
			aElement.attribs.href.includes("https://cfn.contentstt.wpenginepowered.com/")
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"https://cfn.contentstt.wpenginepowered.com/",
				"https://cfn." + config.public.current_hostname.split("//")[1]
			);
		}

		if (
			aElement.attribs.href.includes("https://contentstt.wpenginepowered.com/") &&
			isImageLink
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"https://contentstt.wpenginepowered.com/",
				config.public.hostname
			);
		}

		if (
			aElement.attribs.href.includes("http://contentstt.wpenginepowered.com/") &&
			isImageLink
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"http://contentstt.wpenginepowered.com/",
				config.public.hostname
			);
		}

		// Replace contentstt with current if the link is not an image
		if (
			aElement.attribs.href.includes("https://contentstt.wpenginepowered.com/") &&
			!isImageLink
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"https://contentstt.wpenginepowered.com/",
				config.public.current_hostname
			);
		}

		if (
			aElement.attribs.href.includes("http://contentstt.wpenginepowered.com/") &&
			!isImageLink
		) {
			aElement.attribs.href = aElement.attribs.href.replace(
				"http://contentstt.wpenginepowered.com/",
				config.public.current_hostname
			);
		}

		if (
			!aElement.attribs.href.startsWith(
				"http://contentstt.wpenginepowered.com/"
			) &&
			!aElement.attribs.href.startsWith(
				"https://contentstt.wpenginepowered.com/"
			) &&
			!aElement.attribs.href.startsWith(config.public.hostname) &&
			isImageLink
		) {
			aElement.attribs.href =
				config.public.hostname.slice(0, -1) + aElement.attribs.href;
		}
	});

	$("h1").remove();

	$("h4:has(span)").each(function () {
		var h4Element = $(this);

		h4Element.addClass("subtitle-content");
	});

	$("table").addClass("post-table-style");

	$("#top-content").append(
		'<div class="read-more-container"><button id="read-more-blur" @click="readMoreContent">Read more</button>'
	);
	return $.html();
};

const tocFormatter = () => {
	if (!entryContent.value) return;

	const toc = entryContent.value.querySelector("#toc_container");

	if (!toc) return;

	toc.classList.add("contracted");

	const spanElement = document.createElement("span");
	spanElement.classList.add("toc_toggle");
	const linkElement = document.createElement("span");
	linkElement.setAttribute("class", "toc_fake_link");
	linkElement.textContent = "Show";
	const leftBracket = document.createTextNode("[");
	const rightBracket = document.createTextNode("]");
	spanElement.appendChild(leftBracket);
	spanElement.appendChild(linkElement);
	spanElement.appendChild(rightBracket);

	const title = toc.querySelector("p.toc_title");

	spanElement.addEventListener("click", (e) => {
		const toc = entryContent.value.querySelector("#toc_container");
		const title = toc.querySelector("p.toc_title .toc_fake_link");

		if (toc.classList.contains("contracted")) {
			toc.classList.remove("contracted");
			title.innerText = "Hide";
		} else {
			toc.classList.add("contracted");
			title.innerText = "Show";
		}
	});

	const tocLinks = toc.querySelectorAll("a");
	tocLinks.forEach((tocLink) => {
		tocLink.addEventListener("click", (e) => {
			const equalizer = 250;
			const targetId = tocLink.getAttribute("href").substring(1);
			const targetElement = document.getElementById(targetId);
			const headerHeight = document.querySelector("header").offsetHeight;
			const targetPosition = targetElement.getBoundingClientRect().top;
			const scrollPosition =
				window.scrollY + targetPosition - headerHeight - equalizer;

			targetElement.classList.add("custom-selection");

			window.scrollTo({
				top: scrollPosition,
				behavior: "smooth"
			});

			setTimeout(() => {
				targetElement.classList.remove("custom-selection");
			}, 1500);

			e.preventDefault();
		});
	});

	title.appendChild(spanElement);
};

const getAuthorAvatarUrl = (author) => {
	const defaultAvatars = [
		"https://secure.gravatar.com/avatar/1f12a26284bdf64b81d4c9b929da71bd?s=48&d=mm&r=g",
		"https://secure.gravatar.com/avatar/66fcee21aa5674d0bb977b532006c482?s=48&d=mm&r=g"
	];

	if (defaultAvatars.includes(author.avatar_urls[48])) {
		return "/images/oldlogo.webp";
	} else {
		return author.avatar_urls[48];
	}
};

const shouldRenderAverageRating = computed(() => {
	if (comments)
		return (
			comments.length > 0 && comments.some((comment) => comment.rating !== "")
		);

	return false;
});

function createStockOverlayComponent(sttData) {
	const stockOverlay = document.createElement("div");
	stockOverlay.classList.add("stock-overlay");
	stockOverlay.setAttribute("data-stock", sttData.SYMBOL);

	// Add image
	const img = document.createElement("img");
	img.src = "/images/overlay-arrow.svg";
	img.alt = "overlay-arrow";
	img.classList.add("overlay-arrow");
	stockOverlay.appendChild(img);

	// Add stock symbol and company name
	const stockSymbol = document.createElement("span");
	stockSymbol.classList.add("stock-symbol");
	const hasIdOnBody = document.getElementById(sttData.SYMBOL);
	const symbolContent = `<b>${sttData.SYMBOL}</b>`;
	stockSymbol.innerHTML = hasIdOnBody
		? `<a class="black-link" href="${$router.path}#${
				sttData.SYMBOL
			}">${symbolContent}</a> - ${sttData.MARKET === "NSC" ? "NASDAQ" : "NYSE"}`
		: symbolContent;
	stockOverlay.appendChild(stockSymbol);

	const stockCompany = document.createElement("span");
	stockCompany.classList.add("stock-company");
	stockCompany.textContent = sttData.NAME;
	stockOverlay.appendChild(stockCompany);

	// Add stock price block
	const stockPriceBlock = document.createElement("div");
	stockPriceBlock.classList.add("stock-price-block");
	const stockPrice = document.createElement("span");
	stockPrice.classList.add("stock-price");
	stockPrice.textContent = `$${sttData.LAST.toFixed(2)}`;
	stockPriceBlock.appendChild(stockPrice);

	const stockPriceChange = document.createElement("span");
	stockPriceChange.classList.add(
		"stock-price-change",
		sttData.NET_CHG > 0 ? "positive" : "negative"
	);
	stockPriceChange.textContent = `${sttData.NET_CHG.toFixed(
		2
	)} (${sttData.PC_CHG.toFixed(2)}%)`;
	stockPriceBlock.appendChild(stockPriceChange);

	stockOverlay.appendChild(stockPriceBlock);

	// Add market cap, volume, and float
	const stockMarketCap = document.createElement("span");
	stockMarketCap.classList.add(
		"stock-market-cap",
		"justify-between",
		"!flex-row"
	);
	stockMarketCap.innerHTML = `
    <div class="inline-flex !text-[16px]"><b>Volume:&nbsp;</b>${formatNumber(
					sttData.VOLUME
				)}</div>
    <div class="inline-flex !text-[16px]"><b>Float:&nbsp;</b>${formatNumber(
					sttData.FLOAT
				)}</div>
  `;
	stockOverlay.appendChild(stockMarketCap);

	// Add day low/high and market balance
	const stockDayLowHigh = document.createElement("span");
	stockDayLowHigh.classList.add("stock-market-cap");
	stockDayLowHigh.innerHTML = `
    <div class="flex mb-[4px] justify-between items-center text-[16px]">
      <b class="text-[16px]">$${sttData.LOW.toFixed(2)}</b>
      <b>Day Low/High</b>
      <b class="text-[16px]">$${sttData.HIGH.toFixed(2)}</b>
    </div>
    <div class="stock-market-balance mb-[4px] text-[16px]">
      <span class="stock-market-balance-ball" style="left: ${calculatePercentageHighLow(
							sttData.LOW,
							sttData.HIGH,
							sttData.LAST
						)}%"></span>
    </div>
  `;
	stockOverlay.appendChild(stockDayLowHigh);

	// Add Get Free Alerts button
	const alertsButton = document.createElement("span");
	alertsButton.classList.add("stock-market-cap", "!pt-[12px]", "pb-[0]");
	alertsButton.innerHTML = `
    <button class="bg-blue-300 hover:bg-blue-500 text-white py-[8px] px-[12px] rounded-[8px] text-[18px] font-medium text-center font-semibold">
      Get Free Alerts
    </button>
  `;
	stockOverlay.appendChild(alertsButton);

	// Append the stockOverlay to a specified parent element, e.g., body
	return stockOverlay;
}

function calculatePercentageHighLow(low, high, last) {
	// This is a placeholder function. Implement your logic to calculate the percentage.
	return ((last - low) / (high - low)) * 100;
}

// Sample sttData for demonstration; replace it with your actual data structure

// Call the function with your data

const lg = 1024;

const breakpoints = reactive(
	useBreakpoints({
		lg
	})
);

const injectScripts = () => {
	const sttChartScript = document.createElement("script");
	sttChartScript.src =
		"https://wdgt01.stockstotrade.com:8082/js/compiled/embede/sttwidget.min.js";
	sttChartScript.async = true;

	const sttChartFnScript = document.createElement("script");
	sttChartFnScript.type = "text/javascript";
	sttChartFnScript.innerHTML = `
		    window.displaySTTChart = function() {
		      window.stt_chart_count = 0;

		      var t = document.getElementsByClassName("stockstotrade_chart")[window.stt_chart_count];

		      var a = t.dataset.symbol;
		      var e = 600;

		      if (typeof t.dataset.height !== 'undefined') {
		        e = parseInt(t.dataset.height, 10);
		      }

		      var o = "100%";

		      if (typeof t.dataset.width !== 'undefined') {
		        o = t.dataset.width;
		      }

		      var d = "chart_" + a.toLowerCase() + "_" + Math.floor(100 * Math.random());
		      t.id = d;

		      (new SttAdvancedEquote).create({
		        asynchTarget: d,
		        symbol: a,
		        allowPanning: 1,
		        dateFormat: "US",
		        period: "1m",
		        width: o,
		        height: e,
		        productCode: encodeURIComponent("monthly_14?utm_source=ts_blog&utm_medium=webchart&utm_content=" + a)
		      });

		      window.stt_chart_count += 1;
		    };
		  `;

	document.head.appendChild(sttChartScript);
	document.head.appendChild(sttChartFnScript);
};

onMounted(async () => {
	if (process.client) {
		isLoading.value = false;

		if (!post) {
			throw createError({
				statusCode: 404,
				statusMessage: "Page Not Found"
			});
		}

		const bad_like_dislike_container = document.querySelector(
			".post-like-dislike-plusCounter-container"
		);

		const hidden_ids = document.querySelectorAll(".hidden-id");

		if (bad_like_dislike_container) {
			bad_like_dislike_container.remove();
		}

		if (hidden_ids.length > 0) {
			[...hidden_ids].forEach((item) => item.remove());
		}

		if (post && post.content.rendered) {
			const stockstotrade_chart = document.querySelectorAll(
				".stockstotrade_chart"
			);

			if (stockstotrade_chart.length > 0) {
				await injectScripts();

				await stockstotrade_chart.forEach(async (item) => {
					setTimeout(() => {
						if (typeof window.displaySTTChart === "function") {
							window.displaySTTChart();
						}
					}, 5000);
				});
			}

			tocFormatter();

			fetchLeftOverInformation();
		}

		twitterScriptParser();

		if (shouldLoadConvertFlow()) {
			loadJQuery();
		}

		if (breakpoints.isGreaterOrEqual("lg")) {
			const stockSpans = document.querySelectorAll("span[data-stock]");

			if (stockSpans.length > 0) {
				stockSpans.forEach((span) => {
					const date = dayjs(Date.now()).format("ddd, D MMM YYYY");

					const secretToken = MD5(date).toString();

					const headers = {
						secretToken: secretToken
					};

					const data = {
						filter: `SYMBOL IN ('${span.innerText}')`,
						prevDay: false,
						fields:
							"VOLUME, TRADES, FLOAT, SHORT_INTEREST,SHORT_INTEREST_RATIO, SYMBOL, NAME, LAST, NET_CHG, PC_CHG, MARKET_CAP, HIGH_52W, LOW_52W, LOW, HIGH, DAY_RANGE, MARKET"
					};

					span.addEventListener("mouseover", async function () {
						if (this.children.length > 0) return;

						const { data: sttData, status } = await axios.post(
							"https://app02.stockstotrade.com:8094/v1.1/symbols",
							data,
							{
								headers
							}
						);

						if (status === 200) {
							// Create a div with the class stock-overlay

							const stockOverlay = createStockOverlayComponent(sttData[0]);
							// Append the stockOverlay to the body
							this.appendChild(stockOverlay);
							//console.log(this);
							// stockOverlay.style.left
						}
					});
				});
			}
		}

		showAds.value = window.adsAreWithUs;

		if (window.adsAreWithUs) {
			await setTimeout(() => {
				const adbutler_link = document.querySelectorAll(
					".ad-buttler-ribbon-container a, .end-of-article-ad a, .adbutler-sidebar-ad a"
				);

				[...adbutler_link].forEach((link) => {
					link?.setAttribute("target", "_blank");
				});
			}, 500);
		}
	}
});

const authorship = computed(() => {
	let payload = {
		reviewer: null,
		factChecker: null
	};

	if (post.fact_checked_by) {
		payload.factChecker = { name: post.fact_checked_by };
	}
	if (post.reviewed_by) {
		payload.reviewer = { name: post.reviewed_by };
	}

	return payload;
});

onUnmounted(() => {
	removeConvertFlowScript();
});

useHead({
	script() {
		const scripts = [
			{
				async: true,
				id: "mktlsid",
				type: "text/javascript",
				src: "https://hgevt001.stockstotrade.com/mkt-weet.js",
				"data-h": WEET_HOST,
				"data-k": WEET_KEY,
				"data-mak": WEET_MAK_KEY,
				"data-mtld": WEET_MAK_TLD,
				"data-mhst": WEET_MAK_HOST,
				"data-phf": "1",
				"data-mgp": "1",
				"data-mofc": "optin_form",
				"data-mlo": WEET_ITR_LIST_ID,
				body: true
			}
		];

		if (schema) {
			scripts.push({
				type: "application/ld+json",
				class: "saswp-schema-markup-output",
				innerHTML: JSON.stringify(schema)
			});
		}
		if (
			post &&
			post.content.rendered &&
			post.content.rendered.includes("stockstotrade_chart")
		) {
		}

		return scripts;
	}
});

watch(entryContent, async (newContent, _) => {
	if (process.client && newContent) {
		const read_more_button = document.querySelector("#read-more-blur");

		if (read_more_button) {
			read_more_button.onclick = () => {
				document.querySelector("#top-content").classList.add("active");
			};
		}
	}
});

watch(adPlacement, async (newContent, _) => {
	if (process.client && newContent) {
		const selector = `.plc719983 .related-post-info div p`;
		let counter = 0;
		const interval = await setInterval(() => {
			const element = document.querySelector(selector);

			if ((element && element) || counter > 10) {
				const htElement = element;

				const text = htElement.innerHTML.split(/&lt;br(?:s*\/)?&gt;/g);
				console.log({ text });
				const pTags = text.map((text) => {
					const pTag = pTag
					.replace(/&lt;a/g, "<a")
					.replace(/&gt;/g, ">")
					.replace(/&lt;\/a&gt;/g, "</a>");
					
					return `<p>${text}</p>`});
				htElement.parentElement.innerHTML = pTags.join('');
				clearInterval(interval);
			}
			counter++;
		}, 500);
	}
});
</script>

<template>
	<Head>
		<Title v-if="metadata">{{ metadata?.title }}</Title>

		<Link
			rel="canonical"
			:href="config.public['current_hostname'].slice(0, -1) + $route.path"
		/>
		<Link
			rel="preconnect"
			:href="config.public['hostname']"
		/>

		<Link
			v-if="
				data &&
				data.post &&
				data.post._embedded &&
				data.post._embedded['wp:featuredmedia'] &&
				data.post._embedded['wp:featuredmedia'][0] &&
				data.post._embedded['wp:featuredmedia'][0].source_url
			"
			rel="preload"
			as="image"
			:href="`${config.public.hostname}cdn-cgi/image/quality=100,format=webp,width=800/${data.post._embedded['wp:featuredmedia'][0].source_url}`"
			type="image/webp"
		/>
	</Head>

	<Head v-if="head_seo">
		<Meta
			property="og:url"
			:content="
				config.public['current_hostname'] +
				$route.params.slug[0] +
				'/' +
				$route.params.slug[1]
			"
		/>

		<template
			v-for="(tag, i) in head_seo"
			:key="i"
		>
			<Meta
				:property="'og:' + tag[0].slice(3)"
				:content="tag[1][0].url || tag[1]"
				v-if="tag[0].startsWith('og_')"
			/>

			<Meta
				:property="'article:' + tag[0].slice(8)"
				:content="tag[1]"
				v-else-if="tag[0].startsWith('article')"
			/>
			<Meta
				:name="'twitter:' + tag[0].slice(8)"
				:content="tag[1]"
				v-else-if="tag[0] !== 'twitter_misc' && tag[0].startsWith('twitter')"
			/>

			<template v-else-if="tag[0] === 'twitter_misc'">
				<Meta
					:name="'twitter:label1'"
					:content="Object.keys(tag[1])[0]"
				/>
				<Meta
					:name="'twitter:data2'"
					:content="Object.values(tag[1])[0]"
				/>
				<Meta
					:name="'twitter:label2'"
					:content="Object.keys(tag[1])[1]"
				/>
				<Meta
					:name="'twitter:data1'"
					:content="Object.values(tag[1])[1]"
				/>
			</template>

			<Meta
				:name="tag[0]"
				:content="tag[1]"
				v-else-if="tag[0] != 'og_image'"
			/>
			<template v-else>
				<Meta
					:property="tag[0].split('_').join(':') + ':type'"
					:content="tag[1][0].type"
				/>
				<Meta
					:property="tag[0].split('_').join(':')"
					:content="tag[1][0].url"
				/>
				<Meta
					:property="tag[0].split('_').join(':') + ':width'"
					:content="tag[1][0].width"
				/>
				<Meta
					:property="tag[0].split('_').join(':') + ':height'"
					:content="tag[1][0].height"
				/>
			</template>
		</template>
	</Head>

	<AdButlerBanner
		v-if="showAds && showAdButlerBanner"
		:ad-id="753780"
		@close="hideBanner"
	/>
	<Row
		tag="section"
		class="bg-white"
	>
		<Container class="blog-content">
			<main
				class="content"
				v-if="data && data.post"
			>
				<img
					v-if="
						data.post &&
						data.post._embedded &&
						data.post._embedded['wp:featuredmedia'] &&
						data.post._embedded['wp:featuredmedia'][0] &&
						data.post._embedded['wp:featuredmedia'][0].source_url
					"
					:src="`${config.public.hostname}cdn-cgi/image/quality=100,format=webp,width=800/${data.post._embedded['wp:featuredmedia'][0].source_url}`"
					class="rounded-[24px] mb-[16px] lg:mb-[24px]"
					alt=""
					sizes="(max-width: 1000px) 100vw, 1000px"
					width="1000"
					height="721"
				/>

				<article
					v-if="data.post && data.post.title.rendered"
					class="format-standard entry"
				>
					<div class="mb-[24px] lg:mb-[32px]">
						<div class="flex justify-between items-center mb-[16px]">
							<a
								v-if="post.categories && post.categories.length > 0"
								class="text-blue-500 font-semibold"
								:href="`/category/${
									$categories.find((item) => item.id === post.categories[0]).slug
								}/`"
							>
								{{ $categories.find((item) => item.id === post.categories[0]).name }}
							</a>
							<div class="inline-flex gap-[12px]">
								<span
									class="text-base text-slate-500 border-solid border-r border-slate-300 leading-none pr-[12px] h-[16px]"
									v-html="dateFormat(data.post.modified)"
								></span>
								<span
									class="text-base text-slate-500 leading-none h-[16px]"
									v-html="`${calculateReadingTime(data.post.content.rendered)} min read`"
								></span>
							</div>
						</div>

						<h1
							v-if="data.post && data.post.title.rendered"
							v-html="data.post && data.post.title.rendered"
							class="blog-title mb-[24px]"
						></h1>

						<AuthorshipBlock
							v-if="data.post && data.post._embedded"
							class="mb-[12px]"
							v-bind="authorship"
							:author="{
								avatar: getAuthorAvatarUrl(data.post._embedded.author[0]),
								name: `${
									data.post._embedded.author[0].name
										? data.post._embedded.author[0].name
										: 'Tim Bohen'
								}`
							}"
						/>
					</div>

					<LazyReferencedStocks
						v-if="stock_referenced && stock_referenced.length > 0"
						:symbols="stock_referenced"
					/>

					<div
						v-if="data.post.content.rendered"
						class="entry-content"
						ref="entryContent"
						v-html="contentFormatter(data.post.content.rendered)"
					></div>

					<template v-if="showAds && show_adbutler">
						<hr class="border-solid border-t border-slate-100 my-[32px]" />
						<div ref="adPlacement">
							<AdButlerItem
								:ad-id="719983"
								:isEndOfArticle="true"
								class="end-of-article-ad"
							/>
						</div>
					</template>
				</article>

				<hr
					class="border-solid border-t border-slate-100 my-[32px]"
					v-if="
						(currentFaqs && currentFaqs.length > 0) ||
						shouldRenderAverageRating ||
						comments
					"
				/>

				<LazyFaqTemplate
					v-if="currentFaqs && currentFaqs.length > 0"
					class="px-0"
					:faqs="currentFaqs"
					:processed-title="metadata?.title"
				/>
				<client-only>
					<average-rating
						v-if="shouldRenderAverageRating"
						:comments="comments"
					></average-rating>

					<comments-list
						v-if="comments"
						:comments="comments"
						:postId="data.post.id"
					></comments-list>
				</client-only>
			</main>

			<aside class="mt-[64px] lg:mt-0">
				<AdButlerItem
					:ad-id="741016"
					v-if="showAds && show_adbutler"
					class="adbutler-sidebar-ad"
				/>

				<div
					class="related-post-container"
					v-if="post_category && post_category.posts.nodes.length > 0"
				>
					<RelatedPost
						:posts="
							post_category.posts.nodes.filter(
								(item) => item.slug !== $router.params.slug[0]
							)
						"
					/>
				</div>
			</aside>
		</Container>
	</Row>
</template>

<style lang="scss">
#top-content {
	height: 240px;
	overflow-y: hidden;
	overflow-x: hidden;
	transition: all 400ms ease-in;
	position: relative;
	width: 100%;
}
#top-content.active {
	height: 100%;
}
#top-content.active .read-more-container {
	display: none;
}

.read-more-container {
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 160px;
	padding: 32px 16px;
	align-items: flex-end;
	backdrop-filter: blur(2px);
	margin: auto;
	left: 0;
	right: 0;
	@include breakpoint(lg) {
		width: 100%;
	}
}

.read-more-container:before {
	position: absolute;
	height: 100%;
	bottom: -6px;
	left: 0;
	right: 0;
	filter: blur(2px);
	content: " ";
	z-index: -1;
	background: linear-gradient(
		0deg,
		#fff 20%,
		rgba(255, 255, 255, 0.5) 50%,
		rgba(255, 255, 255, 0)
	);
	width: 120%;
	margin: 0 -32px;
	opacity: 0.95;
}

#read-more-blur {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	max-width: 220px;

	border-radius: 8px;
	background: $blue-300;
	padding: 16px;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 27px */
	letter-spacing: 0.18px;
	color: white;
}

.form-control {
	border-radius: 12px;
	border: 1px solid $slate-100;
	background: $slate-25;
	padding: 12px 16px;
	line-height: 1;
	font-size: 16px;
	width: 100%;
	color: $slate-900;
	text-align: center;
}

.lead-capture {
	border-radius: 24px;
	border: 2px solid $slate-100;
	background: $white;
	padding: 32px 24px;
	display: flex;
	flex-direction: column;
	gap: 32px;
	@include breakpoint(lg) {
		//position: sticky; Commented only to test RelatedPosts.vue
		top: 88px;
	}
}
.blog-content {
	@include breakpoint(lg) {
		display: grid;
		grid-template-columns: 800px 1fr;
		gap: 56px;
	}
}

.blog-title {
	@include openSauceSans500;

	font-size: 28px;
	color: $slate-900;
	line-height: normal;
	@include breakpoint(lg) {
		font-size: 36px;
		line-height: 1.25;
	}
}
.entry-content textarea {
	width: 100%;
}
.entry-content ul {
	list-style: initial;
	padding-left: 16px;
}

.entry-content li {
	@include dmSans;
	color: $slate-900;
	font-size: 18px;
	padding-left: 8px;
	margin-bottom: 28px;
	a {
		color: $blue-300;
	}
}
.entry-content h3 {
	@include dmSans;
	display: block;
	font-weight: 600;
	margin-top: 32px;
	font-size: 18px;
	color: $slate-900;
	margin-bottom: 24px;

	@include breakpoint(lg) {
		font-size: 20px;

		margin-bottom: 32px;
	}
}
.entry-content h2 {
	@include dmSans;
	display: block;
	font-weight: 600;
	margin-top: 32px;
	font-size: 20px;
	color: $slate-900;
	margin-bottom: 32px;

	@include breakpoint(lg) {
		font-size: 24px;

		margin-bottom: 36px;
	}
}

.entry-content iframe {
	width: 100%;

	@include breakpoint(sm) {
		min-height: 400px;
		max-width: 716px;
	}

	@include breakpoint(lg) {
		min-height: 450px;
		max-width: initial;
	}
}

.entry-content p {
	@include dmSans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	color: $slate-900;
	margin-bottom: 24px;
	a {
		color: $blue-300;
		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(lg) {
		font-size: 18px;
		margin-bottom: 28px;
	}

	img.aligncenter {
		margin: auto;
	}
}

.wp-caption {
	width: 100% !important;
	display: block;
}

.wp-caption-text {
	padding: 8px 12px;
	margin-bottom: 0;
	color: $slate-600;
	font-size: 16px;
	background-color: $slate-25;
}

.related-post-container {
	margin-top: 32px;
}

.author-link {
	color: $blue-500;

	&:hover {
		text-decoration: underline;
	}
}

.subtitle-content {
	span {
		font-weight: 600;
		margin-top: 32px;
		font-size: 18px;
		color: #0d0d12;
	}
}

.post-table-style {
	color: $slate-900;
	margin-bottom: 24px;
	text-align: center;
}

.custom-selection {
	padding: 8px;
	background: linear-gradient(
		110deg,
		rgba(11, 45, 143, 0.99) 8.05%,
		rgba(63, 14, 202, 0.98) 91.58%
	);
	color: white;
}

.red-text {
	color: red;
}

.post-table-style {
	border: 1px solid #e2e8f0;
	border-collapse: collapse;
	border-spacing: 0;
	display: block;
	margin-bottom: 32px;
	overflow: auto;
	width: 100%;
	height: auto !important;
	@include breakpoint(lg) {
		overflow-y: visible;
	}
}
.post-table-style tr {
	@include breakpoint(lg) {
		width: 100%;
		display: inline-table;
	}
}
.post-table-style tr:first-child {
	background-color: #4383ff !important;
	position: sticky;
	top: 0;
	td {
		text-align: left !important;
		color: #fff;
	}
}
.post-table-style tr:nth-child(2n + 1) {
	background-color: #eff6ff;
}

.post-table-style td,
.post-table-style th {
	padding: 12px 8px;
	text-align: left;
	white-space: nowrap;
	@include breakpoint(md) {
		padding: 12px 16px;
	}
}
.post-table-style th {
	background-color: #3b82f6;
	color: #fff;
	padding-bottom: 11px;
	padding-top: 11px;
}

span[data-stock],
.symbol-item {
	position: relative;

	&:hover .stock-overlay {
		display: none;
	}
	@include breakpoint(lg) {
		.stock-overlay {
			display: none;
			position: absolute;
			background-color: white;
			border: 1px solid $slate-200;
			padding: 16px;
			left: 140%;
			top: -20px;
			border-radius: 8px;
			z-index: 9;
			&::before {
				content: "";
				background-image: url("/_nuxt/images/overlay-arrow.svg");
				position: absolute;
				top: 16px;
				left: -18px;
				z-index: 0;
				transform: rotate(-90deg) scale(0.75);
				height: 32px;
				width: 32px;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&:hover {
			.stock-overlay {
				display: flex;
				width: 320px;
				flex-direction: column;
			}
		}
	}
}

.stock-overlay.active {
	display: flex;
	width: 100%;
	background-color: white;
	border: 1px solid $slate-200;
	padding: 16px;
	z-index: 9;
	flex-direction: column;
	margin-top: 8px;
	border-radius: 12px;
	position: relative;
}
span[data-stock] {
	&::before {
		position: absolute;
		height: 100%;
		width: 170%;
		content: "";
		left: -150%;
	}
	@include breakpoint(lg) {
		.stock-overlay::before {
			content: "";
			background-image: url("/images/overlay-arrow.svg");
			position: absolute;
			top: 16px;
			left: -18px;
			z-index: 0;
			transform: rotate(-90deg) scale(0.75);
			height: 32px;
			width: 32px;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}

.symbol-item {
	.stock-overlay {
		display: none;
		border-radius: 8px;
		@include breakpoint(lg) {
			left: 105%;
		}
	}
}
.stock-symbol {
	// font-weight: 700;
	font-size: 16px;
	color: $slate-900;
	a {
		color: $blue-300;
	}
	a:hover {
		text-decoration: underline;
	}
}

.stock-link,
span[data-stock] {
	cursor: pointer;
	color: $blue-300;
	&:hover {
		text-decoration: underline;
	}
}
a.black-link {
	color: $blue-300 !important;
}
a.black-link:hover {
	text-decoration: underline !important;
}

.stock-company {
	font-size: 16px;
	color: $slate-900;
}

.stock-price-block {
	display: flex;
	flex-direction: row;
}

.stock-price {
	font-weight: 700;
	font-size: 18px;
	color: $slate-900;
	margin-right: 4px;
}

.stock-price-change {
	font-size: 18px;
	font-weight: 700;
	color: $slate-500;
	&.positive {
		color: $green-300;
	}
	&.negative {
		color: $red-300;
	}
}

.stock-market-cap {
	display: flex;
	font-size: 14px;
	color: $slate-600;
	width: 100%;
	margin-top: 4px;
	padding-top: 4px;
	border-top: 1px solid $slate-100;
	flex-direction: column;
}

.black-link {
	color: #0d0d12 !important;
}

.ad-buttler-ribbon-outer-wrapper {
	background-color: #ffeb3b;
}


.ad-buttler-ribbon-outer-wrapper p{
	color: #222;
}
</style>
